import Head from "next/head";
import parse from 'html-react-parser';
import { PostSeoFieldsFragment, TaxonomySeoFieldsFragment } from "@/generated/graphql";

interface SeoProps {
    data: PostSeoFieldsFragment | TaxonomySeoFieldsFragment | null | undefined;
}

// Utility function to replace only specific URLs intended for frontend display
function replaceUrls(content: string | null | undefined): string | null {
    if (!content) return null; // Ensure undefined is handled explicitly

    const backendUrl = process.env.NEXT_PUBLIC_WORDPRESS_URL?.replace(/\/$/, '') || '';
    const frontendUrl = process.env.FRONTEND_URL?.replace(/\/$/, '') || '';

    if (!backendUrl || !frontendUrl) {
        console.error("Environment variables NEXT_PUBLIC_WORDPRESS_URL or FRONTEND_URL are not set.");
        return content;
    }

    // Use a regular expression to replace backend URLs with frontend URLs only if they are in `href` or `content` attributes
    return content.replace(new RegExp(`(https?:\/\/)(${backendUrl.replace(/https?:\/\//, '').replace(/\//g, '\\/')})(\/[^"'>]*)`, 'g'), (match, protocol, domain, path) => {
        // Preserve the domain for image URLs and other non-frontend items
        if (match.includes("wp-content") || match.includes("uploads")) {
            return `${protocol}${domain}${path}`; // Keep as is
        }
        return `${protocol}${frontendUrl.replace(/https?:\/\//, '')}${path}`; // Replace domain
    });
}

// For use with PostSeoFieldsFragment
export function Seo({ data }: SeoProps) {
    console.log('SEO Data:', data);

    // Process the `fullHead` to ensure URLs are replaced correctly
    const processedFullHead = replaceUrls(data?.fullHead);

    return ( 
        <Head>
            <title>{data?.title}</title>
            {processedFullHead && parse(processedFullHead)}
        </Head>
    );
}
